<template>
    <div class='bodyBox'>
      <section class='pay_top_box'>
        <div class='pay_info'>
          <div>订单编号：{{this.payInfo.out_trade_no || ''}}</div>
          <div>应付金额 <span class='pay_count'>{{this.payInfo.total_fee? this.payInfo.total_fee/100 : 0}}</span> 元</div>
        </div>
      </section>
      <section>
        <div class= 'bottom_box'>
          <div class= 'WxPayText'>微信支付</div>
          <div class= 'img_Box'>
            <div class= 'Qr_code_box'>
              <div class= 'Qr_code'>
                <vue-qr class="wximg"
                    :size="191"
                    :margin="0"
                    :auto-color="true"
                    :dot-scale="1"
                    :text="payInfo.code_url"/>
              </div>

              <div class= 'mes_red_bg'>
                <img src="../../assets/images/icon-red.png" alt=""/>
                <span style= 'margin-left:30px'>请使用微信扫一扫<br/>扫描二维码支付</span>
              </div>
            </div>
            <div style= 'margin-left:50px'>
              <img src="../../assets/images/phone-bg.png" alt=""/>
            </div>
          </div>
          <div class= 'pay_success_msg'>提示：支付成功后关闭此页面即可</div>
        </div>
      </section>
    </div>
</template>

<script>


import {getPayInfo} from "../../http/request";
import VueQr from "vue-qr";
export default {
    components:{VueQr},
    props: {},
    data() {
        return {
            payInfo:{}
        }
    },
    methods: {
        getPayInfo() {
            getPayInfo(this.$route.params.userId).then(({data})=>{
                this.payInfo = data
            })
        }
    },
    mounted() {
        this.getPayInfo()
    },
}
</script>

<style lang="scss">
    .bodyBox {
    // width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
    }
    .pay_top_box {
      margin-top: 60px;
    } 
    .pay_info {
      width: 990px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
    .bottom_box{
      width: 990px;
      height: 611px;
      box-shadow: 0px 5px 15px #ddd;
      margin-top: 20px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .WxPayText {
     font-size: 20px;
    }
    .mes_red_bg {
      width: 302px;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #ff7674;
      color: #ffffff;
      img {
          width: 44px;
          height: 44px;
      }
    }
    .img_Box{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .Qr_code_box {
      height: 390px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
    .pay_success_msg {
      font-size: 16px;
      color: #999;
      margin-top: 30px;
    }
    .pay_count {
      color: #ff3333;
      font-weight: bold;
    }
    .Qr_code {
      width: 302px;
      height: 302px;
      border: 1px solid #ddd;
      margin-bottom: 20px;
    }
</style>